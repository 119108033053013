import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menu | Lagoon Linger: Dive into Delights
			</title>
			<meta name={"description"} content={"Dip your toes into the tantalising tastes of Lagoon Linger. This menu offers just a glimpse – the full story awaits at our shores."} />
			<meta property={"og:title"} content={"Menu | Lagoon Linger: Dive into Delights"} />
			<meta property={"og:description"} content={"Dip your toes into the tantalising tastes of Lagoon Linger. This menu offers just a glimpse – the full story awaits at our shores."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14:23:43.447Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Lagoon Linger's Liquid Library
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Dip your toes into the tantalising tastes of Lagoon Linger. This menu offers just a glimpse – the full story awaits at our shores.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14:55:44.413Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.1.jpg?v=2023-10-23T14%3A55%3A44.413Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Deep Dive
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							A cocktail that truly rises to the occasion. Experience a blend of tropical flavors that emerge from the depths of our unique bar counters.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					margin="0px 30px 0px 30px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14:55:44.421Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.2.jpg?v=2023-10-23T14%3A55%3A44.421Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Moonlit Mirage
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Gin meets elderflower in this concoction, paired with a hint of blue curaçao for a glow reminiscent of moonlight over the lagoon.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14:55:44.414Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_1.1.jpg?v=2023-10-23T14%3A55%3A44.414Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Mermaid's Melody
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							A melody of berry-infused vodka and delicate floral notes, garnished with edible pearls that dance in the drink.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14:55:44.403Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_0.4.jpg?v=2023-10-23T14%3A55%3A44.403Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Nibbles by the Lagoon
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							While our focus remains on the liquid tales, our snack selection complements each sip.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14:55:44.393Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/cocktail_3.3.jpg?v=2023-10-23T14%3A55%3A44.393Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Lagoon Crisps
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Delicate crisps seasoned with seaweed and salt, perfect to accompany your drink.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14:27:19.391Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_1.2.jpg?v=2023-10-23T14%3A27%3A19.391Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Treasure Bites
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Mini seafood tacos drizzled with our house-special sauce.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				What you see here is just the crest of the wave. Dive into Lagoon Linger to explore the full depth of our menu. For any specific concerns or requirements (be it dietary, vegan, or health-related), please don't hesitate to reach out. We are more than happy to cater to your unique needs.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				background="#ffdd61"
				color="--dark"
				type="link"
				href="/contact-us"
				text-decoration-line="initial"
			>
				Find Us
			</Button>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});